<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    style="enable-background:new 0 0 512.001 512.001;"
    xml:space="preserve"
  >
    <g>
      <rect
        x="45.176"
        y="380.251"
        style="fill:#65B4BB;"
        width="84.876"
        height="120.795"
      />
      <rect
        x="381.95"
        y="380.251"
        style="fill:#65B4BB;"
        width="84.876"
        height="120.795"
      />
    </g>
    <g>
      <rect
        x="45.176"
        y="352.964"
        style="fill:#57A3A7;"
        width="84.876"
        height="120.795"
      />
      <rect
        x="381.95"
        y="352.964"
        style="fill:#57A3A7;"
        width="84.876"
        height="120.795"
      />
    </g>
    <rect y="10.956" style="fill:#65B4BB;" width="512.001" height="443.557" />
    <path
      style="fill:#57A3A7;"
      d="M469.73,412.234H42.272V53.226H469.73V412.234z M61.772,392.734H450.23V72.726H61.772V392.734z"
    />
    <circle style="fill:#FED159;" cx="256.007" cy="232.728" r="99.935" />
    <g>
      <path
        style="fill:#FBB53A;"
        d="M255.999,123.045c-60.481,0-109.685,49.205-109.685,109.685s49.205,109.685,109.685,109.685
		s109.687-49.205,109.687-109.685S316.481,123.045,255.999,123.045z M255.999,267.472c-19.157,0-34.742-15.586-34.742-34.742
		s15.586-34.742,34.742-34.742s34.742,15.586,34.742,34.742S275.157,267.472,255.999,267.472z M286.816,188.128
		c-6.21-4.303-13.355-7.337-21.065-8.744V143.08c17.568,1.898,33.632,8.865,46.713,19.4L286.816,188.128z M246.249,179.384
		c-7.71,1.407-14.855,4.441-21.065,8.744l-25.648-25.648c13.082-10.535,29.145-17.502,46.713-19.4V179.384z M211.397,201.915
		c-4.303,6.209-7.337,13.355-8.744,21.065h-36.304c1.898-17.568,8.865-33.632,19.4-46.713L211.397,201.915z M202.653,242.48
		c1.407,7.71,4.441,14.856,8.744,21.065l-25.648,25.648c-10.535-13.082-17.502-29.145-19.4-46.713H202.653z M225.186,277.334
		c6.209,4.303,13.355,7.337,21.065,8.744v36.304c-17.568-1.898-33.631-8.865-46.713-19.4L225.186,277.334z M265.749,286.078
		c7.71-1.405,14.856-4.441,21.065-8.744l25.648,25.648c-13.082,10.535-29.145,17.502-46.713,19.4V286.078z M300.603,263.545
		c4.303-6.21,7.338-13.355,8.744-21.065h36.304c-1.898,17.568-8.865,33.632-19.4,46.713L300.603,263.545z M309.347,222.98
		c-1.407-7.71-4.441-14.856-8.744-21.065l25.648-25.648c10.535,13.082,17.502,29.145,19.4,46.713H309.347z"
      />
      <circle style="fill:#FBB53A;" cx="422.978" cy="98.322" r="9.75" />
      <circle style="fill:#FBB53A;" cx="422.978" cy="367.147" r="9.75" />
      <circle style="fill:#FBB53A;" cx="89.023" cy="98.322" r="9.75" />
      <circle style="fill:#FBB53A;" cx="89.023" cy="367.147" r="9.75" />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
